<template>
  <v-card
    class="mx-auto"
    color="#26c6da"
    dark
    max-width="400"
  >
    <v-card-title>
      <v-icon
        large
        left
      >
        mdi-star
      </v-icon>
      <span class="title font-weight-light">Table 41</span>
    </v-card-title>

        <v-card class="py-10 mx-5 my-15">
          <v-card-title >
            Under Construction...
          </v-card-title>
        </v-card>
        <div class="mx-5 py-10 my-15">
          <v-btn block to='/'>Back to Home</v-btn>
        </div>
  </v-card>
</template>

<script>
export default ({
  name: 'notready'
})
</script>